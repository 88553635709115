import { createContainer, createHook, createStore } from 'react-sweet-state';
import { fg } from '@atlaskit/platform-feature-flags';
const actions = {
  load: (jiraField, query, projectId, issueTypeId, requestTypeId, templateFormId, fieldConfigId, fieldValueMap) => ({
    getState,
    setState
  }, {
    apiUtil,
    reporterAccountId
  }) => {
    if (!getState().loading) {
      setState({
        data: undefined,
        error: undefined,
        loading: true
      });
      const uri = `/rest/servicedesk/cmdb/1/proforma/field/${jiraField}/config/${fieldConfigId}/autocomplete`;
      apiUtil.jiraPost(uri, {
        query,
        templateFormId,
        fieldValueMap: {
          ...(reporterAccountId ? {
            reporter: reporterAccountId
          } : {}),
          ...fieldValueMap,
          pid: projectId,
          issuetype: issueTypeId,
          requesttype: requestTypeId
        }
      }, 'cmdbAutocomplete').then(data => data.results.map(cmdbObject => ({
        value: cmdbObject.id,
        label: cmdbObject.label,
        ...(fg('proforma_asset_fields_show_selected_attributes') ? {
          cmdbObject
        } : {})
      }))).then(cmdbObjects => {
        setState({
          data: cmdbObjects,
          loading: false
        });
      }).catch(apiError => {
        setState({
          error: apiError,
          loading: false
        });
      });
    }
  }
};
const Store = createStore({
  initialState: {
    data: undefined,
    error: undefined,
    loading: false
  },
  actions,
  name: 'CmdbChoicesService'
});
export const CmdbChoicesServiceContainer = createContainer(Store);
export const useCmdbChoicesService = createHook(Store);