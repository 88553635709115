/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Norwegian (Bokmål)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Opprett en sak til',
  'proforma-jira-issue-forms.common.ui.footer.create-another-label-issue-term-refresh': 'Opprett en arbeid til',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Opprett',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Prøv igjen senere.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Vi kan ikke opprette saken din.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title-issue-term-refresh': 'Vi kan ikke opprette arbeidet din.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Håndter eventuelle ugyldige svar og sjekk at alle obligatoriske felt er fylt ut.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Kopier kobling',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Du opprettet saken «{issueKey}».',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title-issue-term-refresh': 'Du opprettet arbeidet «{issueKey}».',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Vis sak',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action-issue-term-refresh': 'Vis arbeid',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Rediger skjema',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Rediger forespørselsskjema',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'Be administratoren din om å flytte <strong>{formFieldName}</strong>-feltet fra den betingede delen, eller fjern «obligatorisk»-statusen fra det tilknyttede Jira-feltet <strong>{jiraFieldName}</strong> i <strong>{typeName}</strong>-forespørselsskjemaet.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'Flytt <strong>{formFieldName}</strong>-feltet fra den betingede delen, eller fjern «obligatorisk»-statusen fra det tilknyttede Jira-feltet <strong>{jiraFieldName}</strong> i <strong>{typeName}</strong>-forespørselsskjemaet.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': '«Obligatorisk» felt i betinget seksjon',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Be administratoren din om å koble et felt i dette skjemaet til Jira-feltet <strong>{jiraFieldName}</strong> i <strong>{typeName}</strong>-forespørselsskjemaet.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Koble et felt i dette skjemaet til Jira-feltet <strong>{jiraFieldName}</strong> i <strong>{typeName}</strong>-forespørselsskjemaet.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': '«Obligatorisk» felt er ikke tilknyttet',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Be administratoren din om å merke <strong>{formFieldName}</strong>-feltet i dette skjemaet som «obligatorisk» for å matche det tilknyttede Jira-feltet <strong>{jiraFieldName}</strong> i <strong>{typeName}</strong>-forespørselsskjemaet.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'Merk <strong>{formFieldName}</strong>-feltet i dette skjemaet som «obligatorisk» for å matche det tilknyttede Jira-feltet <strong>{jiraFieldName}</strong> i <strong>{typeName}</strong>-forespørselsskjemaet.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': '«Obligatorisk» felt matcher ikke',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Oppdater siden og prøv på nytt.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Vi kan ikke laste inn skjemaet ditt',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Fjern attributter som ikke lenger finnes i konfigurasjonsfilteret for det egendefinerte Ressurser-feltet, og påse at AQL-spørringen er gyldig.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'Vi kan ikke hente noen objekter for dette feltet',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Opprett en sak til',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed-issue-term-refresh': 'Opprett en arbeid til',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Vis <a>issueKey</a> for å oppdatere, eller administrer denne saken.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed-issue-term-refresh': 'Vis <a>issueKey</a> for å oppdatere, eller administrer denne arbeidet.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Vi har opprettet saken',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed-issue-term-refresh': 'Vi har opprettet arbeidet',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Opprett saker ved hjelp av innebygde skjemaer ved å lime inn denne koblingen på et Atlassian-nettsted. <a>Kopier kobling</a>',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed-issue-term-refresh': 'Opprett arbeider ved hjelp av innebygde skjemaer ved å lime inn denne koblingen på et Atlassian-nettsted. <a>Kopier kobling</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Oppdater siden og prøv på nytt.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Vi kan ikke laste inn skjemaene dine',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Oppdater siden og prøv på nytt.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Vi har problemer med å laste inn prosjektene dine.',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Velg skjema',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Velg sakstype',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder-issue-term-refresh': 'Velg arbeidstype',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Opprett saker ved hjelp av et skjema, eller direkte fra en kobling til et skjema. <a>Finn ut mer om å bruke skjemaer til å opprette saker.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed-issue-term-refresh': 'Opprett arbeider ved hjelp av et skjema, eller direkte fra en kobling til et skjema. <a>Finn ut mer om å bruke skjemaer til å opprette arbeider.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Opprett sak',
  'proforma-jira-issue-forms.ui.general.header.page-header-issue-term-refresh': 'Opprett arbeid',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Dette serviceprosjektet har ingen tilknyttede skjemaer.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Dette programvareprosjektet har ingen tilknyttede skjemaer.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Dette virksomhetsprosjektet har ingen tilknyttede skjemaer.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Velg prosjekt',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Velg forespørselstype'
};