/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Japanese
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': '別の課題を作成',
  'proforma-jira-issue-forms.common.ui.footer.create-another-label-issue-term-refresh': '別の作業項目を作成',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': '作成',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': '後ほどもう一度お試しください。',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': '課題を作成できませんでした。',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title-issue-term-refresh': '作業項目を作成できませんでした。',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': '無効な回答を解決して、すべての「必須」フィールドが入力済みであることをご確認ください。',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'リンクをコピー',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': '課題「{issueKey}」を作成しました。',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title-issue-term-refresh': '作業項目「{issueKey}」を作成しました。',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': '課題を表示',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action-issue-term-refresh': '作業項目を表示',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'フォームを編集',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'リクエスト フォームを編集',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': '<strong>{formFieldName}</strong> フィールドを条件付きセクションから移動する、または <strong>{typeName}</strong> リクエスト フォーム上のリンクされた <strong>{jiraFieldName}</strong> Jira フィールドから「必須」ステータスを削除するには、管理者にご依頼ください。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': '<strong>{formFieldName}</strong> フィールドを条件付きセクションから移動するか、<strong>{typeName}</strong> リクエスト フォーム上のリンクされた <strong>{jiraFieldName}</strong> Jira フィールドから「必須」ステータスを削除します。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': '条件付きセクションの「必須」フィールド',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'このフォームのフィールドを <strong>{typeName}</strong> リクエスト フォームの <strong>{jiraFieldName}</strong> Jira フィールドとリンクするには、管理者にご依頼ください。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'このフォームのフィールドを <strong>{typeName}</strong> リクエスト フォームの <strong>{jiraFieldName}</strong> Jira フィールドとリンクします。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': '「必須」フィールドがリンクされていません。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'このフォームの <strong>{formFieldName}</strong> フィールドを「必須」としてマークして、<strong>{typeName}</strong> リクエスト フォーム上のリンクされた <strong>{jiraFieldName}</strong> Jira フィールドと一致させるには、管理者にご依頼ください。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'このフォームの <strong>{formFieldName}</strong> フィールドを「必須」としてマークして、<strong>{typeName}</strong> リクエスト フォーム上のリンクされた <strong>{jiraFieldName}</strong> Jira フィールドに一致させます。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': '「必須」フィールドが一致していません。',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'ページを更新して、もう一度お試しください。',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'フォームを読み込めませんでした。',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'アセット カスタム フィールド設定フィルターにすでに存在しない属性を削除し、AQL クエリが有効であることを確認してください。',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'このフィールドのオブジェクトを取得できませんでした',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': '別の課題を作成',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed-issue-term-refresh': '別の作業項目を作成',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': '<a>issueKey</a> を表示して更新するか、この課題の管理を行います。',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed-issue-term-refresh': '<a>issueKey</a> を表示して更新するか、この作業項目の管理を行います。',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': '課題を作成しました',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed-issue-term-refresh': '作業項目を作成しました',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'このリンクを任意のアトラシアン サイトに貼り付けて、埋め込みフォームで課題を作成します。<a>リンクをコピー</a>',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed-issue-term-refresh': 'このリンクを任意のアトラシアン サイトに貼り付けて、埋め込みフォームで作業項目を作成します。<a>リンクをコピー</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'ページを更新して、もう一度お試しください。',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'フォームの読み込み中に問題が発生しました。',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'ページを更新して、もう一度お試しください。',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'プロジェクトの読み込み中に問題が発生しました。',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'フォームを選択',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': '課題タイプを選択',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder-issue-term-refresh': '作業項目タイプを選択',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'フォームを使用するか、フォームへのリンクから直接課題を作成します。<a>フォームによる課題作成の詳細についてご確認ください。</a>',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed-issue-term-refresh': 'フォームを使用するか、フォームへのリンクから直接作業項目を作成します。<a>フォームによる作業項目作成の詳細についてご確認ください。</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': '課題を作成',
  'proforma-jira-issue-forms.ui.general.header.page-header-issue-term-refresh': '作業項目を作成',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'このサービス プロジェクトに関連付けられたフォームはありません。',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'このソフトウェア プロジェクトに関連付けられたフォームはありません。',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'このビジネス プロジェクトに関連付けられたフォームはありません。',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'プロジェクトを選択',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'リクエスト タイプを選択'
};