import _initializerDefineProperty from "@babel/runtime/helpers/initializerDefineProperty";
import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";
import _initializerWarningHelper from "@babel/runtime/helpers/initializerWarningHelper";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _QuestionStore;
import { action, computed, observable, toJS } from 'mobx';
import { getEmptyADF } from '@atlaskit/adf-utils/empty-adf';
import { fg } from '@atlaskit/platform-feature-flags';
import { ChoiceMessage, IntlChoiceMessages } from '../components/questions/ChoiceMessages.intl';
import { FormAnswerEmpty, FormQuestionType } from '../models/Form';
import { asyncChoicesFieldSchemaCustoms, asyncChoicesFieldSchemaCustomsWithEntitlement, asyncChoicesFieldSchemaSystems } from '../utils/jiraFieldUtils';
import { getAttachmentValidation, getChoiceValidation, getDateTimeValidation, getDateValidation, getNumberValidation, getRichTextValidation, getTextValidation, getTimeValidation, getUserPickerValidation } from '../utils/validation/validators';

// extracted FormAnswer data, done in extractAnswer()

// was: { id: string; name: string };

// Array.isArray() returns false for mobx ObservableArray - see "known pitfalls" in mobX docs
const isArray = value => {
  return !!value && value.length != null && value.splice != null;
};
export const isRichTextQuestionAnswer = answer => {
  return typeof answer === 'object' && answer.hasOwnProperty('content') && answer.hasOwnProperty('version') && answer.hasOwnProperty('type') && answer.type === 'doc';
};
export const toRichTextQuestionAnswer = answer => isRichTextQuestionAnswer(answer) ? answer : undefined;
const isTextQuestionAnswer = answer => typeof answer === 'string';
export const toTextQuestionAnswer = answer => isTextQuestionAnswer(answer) ? answer : undefined;
const isDateTimeQuestionAnswer = answer => (answer === null || answer === void 0 ? void 0 : answer.date) !== undefined;
export const toDateTimeQuestionAnswer = answer => isDateTimeQuestionAnswer(answer) ? answer : undefined;
export const toDateTimeQuestionAnswerOrEmpty = answer => isDateTimeQuestionAnswer(answer) ? answer : {
  date: '',
  time: ''
}; // same default data as in extractAnswer()

const isChoiceQuestionAnswer = answer => (answer === null || answer === void 0 ? void 0 : answer.choices) !== undefined;
export const toChoiceQuestionAnswer = answer => isChoiceQuestionAnswer(answer) ? answer : undefined;
export const toChoiceQuestionAnswerOrEmpty = answer => isChoiceQuestionAnswer(answer) ? answer : {
  text: '',
  choices: []
};
const isUserPickerQuestionAnswer = answer => {
  return isArray(answer);
};
export const toUserPickerQuestionAnswer = answer => isUserPickerQuestionAnswer(answer) ? answer : undefined;
export const toUserPickerQuestionAnswerOrEmpty = answer => isUserPickerQuestionAnswer(answer) ? answer : [];
export const isAttachmentQuestionAnswer = answer => isArray(answer) && answer.every(a => a.id !== undefined);
export const toAttachmentQuestionAnswer = answer => isAttachmentQuestionAnswer(answer) ? answer : undefined;
const extractAnswer = (questionType, answer) => {
  var _console$debug, _console;
  switch (questionType) {
    case FormQuestionType.TextShort:
    case FormQuestionType.TextLong:
    case FormQuestionType.TextParagraph:
    case FormQuestionType.TextEmail:
    case FormQuestionType.TextUrl:
    case FormQuestionType.Number:
      return answer.text || '';
    case FormQuestionType.DateTime:
      return {
        date: answer.date || '',
        time: answer.time || ''
      };
    case FormQuestionType.Date:
      return answer.date || '';
    case FormQuestionType.Time:
      return answer.time || '';
    case FormQuestionType.ChoiceCascading:
    case FormQuestionType.ChoiceSingle:
    case FormQuestionType.ChoiceMultiple:
    case FormQuestionType.ChoiceDropdown:
    case FormQuestionType.ChoiceDropdownMultiple:
    case FormQuestionType.CmdbObject:
      return {
        text: answer.text || '',
        choices: answer.choices || []
      };
    case FormQuestionType.UserSingle:
    case FormQuestionType.UserMultiple:
      return answer.users || [];
    case FormQuestionType.RichTextParagraph:
      return answer.adf || getEmptyADF();
    case FormQuestionType.Attachment:
      return answer.files || [];
    default:
      // eslint-disable-next-line no-console
      (_console$debug = (_console = console).debug) === null || _console$debug === void 0 ? void 0 : _console$debug.call(_console, `Question type ${questionType} is not handled.`);
      return '';
  }
};
const sortById = (a, b) => {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
};
export let QuestionStore = (_class = (_QuestionStore = class QuestionStore {
  constructor(intl, questionKey, _formQuestion, loadChoicesAsync, onFieldChange, populateDefaultValueOnEmptySearch, _currentAnswer, onFieldDirty) {
    var _formQuestion$conditi;
    this.intl = intl;
    this.id = void 0;
    this.formQuestion = void 0;
    this.conditions = void 0;
    this.hasJiraField = void 0;
    this.hasDataConnection = void 0;
    _initializerDefineProperty(this, "answer", _descriptor, this);
    _initializerDefineProperty(this, "modifiedAnswer", _descriptor2, this);
    _initializerDefineProperty(this, "validating", _descriptor3, this);
    _initializerDefineProperty(this, "loading", _descriptor4, this);
    _initializerDefineProperty(this, "isUploadingMedia", _descriptor5, this);
    _initializerDefineProperty(this, "choices", _descriptor6, this);
    _initializerDefineProperty(this, "answerChoicesAsObjects", _descriptor7, this);
    _initializerDefineProperty(this, "dependentFieldChangedCount", _descriptor8, this);
    _initializerDefineProperty(this, "otherChoiceId", _descriptor9, this);
    _initializerDefineProperty(this, "revisionToken", _descriptor10, this);
    _initializerDefineProperty(this, "issueFieldData", _descriptor11, this);
    this.loadChoicesAsync = void 0;
    this.onFieldChange = void 0;
    this.populateDefaultValueOnEmptySearch = void 0;
    this.onFieldDirty = void 0;
    this.attachmentEqual = (a1, a2) => {
      if (a1 === undefined || a2 === undefined) {
        return false;
      }
      return a1['id'] === a2['id'];
    };
    this.hasAttachmentFieldModified = () => {
      const af1 = this.answer;
      const af2 = this.modifiedAnswer;
      if (af1 === undefined || af2 === undefined) {
        return false;
      }
      return !(af1.length === af2.length && af1.every((a, idx) => this.attachmentEqual(a, af2[idx])));
    };
    this.choiceToOption = choice => {
      var _choice$children;
      return {
        value: choice.id,
        label: choice.label,
        children: (_choice$children = choice.children) === null || _choice$children === void 0 ? void 0 : _choice$children.map(this.choiceToOption),
        ...(fg('proforma_asset_fields_show_selected_attributes') ? {
          cmdbObject: choice.cmdbObject
        } : {})
      };
    };
    this.availableOptions = () => {
      return this.choices.map(this.choiceToOption);
    };
    /**
     * Use only for choices fields
     */
    this.getAnswerChoicesAsObjects = () => {
      const choices = this.answerChoicesAsObjects;
      const currentChoiceOptions = choices.map(this.choiceToOption);
      const choiceAnswer = toChoiceQuestionAnswerOrEmpty(this.currentAnswer);
      return currentChoiceOptions.filter(option => choiceAnswer.choices.includes(option.value) ||
      // If the answer is a cascading response, this will return the parent choice
      choiceAnswer.choices.map(choice => choice.split(':')[0]).includes(option.value));
    };
    _initializerDefineProperty(this, "setLastUpdated", _descriptor12, this);
    _initializerDefineProperty(this, "setAnswer", _descriptor13, this);
    _initializerDefineProperty(this, "doPopulateDefaultValueOnEmptySearch", _descriptor14, this);
    _initializerDefineProperty(this, "onDependentFieldChanged", _descriptor15, this);
    _initializerDefineProperty(this, "validate", _descriptor16, this);
    _initializerDefineProperty(this, "hideValidation", _descriptor17, this);
    _initializerDefineProperty(this, "setUploadingMedia", _descriptor18, this);
    _initializerDefineProperty(this, "discardModifiedAnswer", _descriptor19, this);
    _initializerDefineProperty(this, "setAnswerFromFieldValue", _descriptor20, this);
    _initializerDefineProperty(this, "setChoices", _descriptor21, this);
    this._setChoices = choices => {
      this.choices.splice(0, this.choices.length, ...choices);
      const otherChoice = this._setOtherChoiceLabel(choices);
      this.otherChoiceId = otherChoice === null || otherChoice === void 0 ? void 0 : otherChoice.id;
    };
    this._setOtherChoiceLabel = choices => {
      const otherChoice = choices.find(choice => choice.other);
      if (otherChoice) {
        otherChoice.label = this.intl.formatMessage(IntlChoiceMessages[ChoiceMessage.OtherPlaceholder]);
      }
      return otherChoice;
    };
    this._isAsyncChoicesQuestion = () => {
      var _this$choices;
      // for non-async choices fields - all choices are fetched on form load
      return !(((_this$choices = this.choices) === null || _this$choices === void 0 ? void 0 : _this$choices.length) > 0);
    };
    _initializerDefineProperty(this, "setAnswerChoicesAsObjects", _descriptor22, this);
    this._setAnswerChoicesAsObjects = answerChoicesAsObjects => {
      this.answerChoicesAsObjects.splice(0, this.answerChoicesAsObjects.length, ...answerChoicesAsObjects);
      this._setOtherChoiceLabel(this.answerChoicesAsObjects);
    };
    _initializerDefineProperty(this, "setIssueFieldData", _descriptor23, this);
    _initializerDefineProperty(this, "update", _descriptor24, this);
    this._update = (constructing, formQuestion, currentAnswer) => {
      this._setChoices(formQuestion.choices || []);
      this.answer = extractAnswer(formQuestion.type, currentAnswer || FormAnswerEmpty);
      if (!constructing) {
        this.hideValidation();
        this.discardModifiedAnswer();
        this.setLastUpdated();
      }
    };
    this.loadChoicesAsync = loadChoicesAsync;
    this.onFieldChange = onFieldChange;
    this.onFieldDirty = onFieldDirty;
    this.populateDefaultValueOnEmptySearch = populateDefaultValueOnEmptySearch;
    this.id = parseInt(questionKey, 10);
    this.revisionToken = `${this.id}-${Date.now()}`;
    this.formQuestion = _formQuestion;
    this.conditions = ((_formQuestion$conditi = _formQuestion.conditions) === null || _formQuestion$conditi === void 0 ? void 0 : _formQuestion$conditi.map(condition => parseInt(condition))) || [];
    this.hasJiraField = !!_formQuestion.jiraField;
    this.hasDataConnection = !!_formQuestion.dcId;
    this._setChoices(_formQuestion.choices || []);
    // this set call is for proforma non-linked dropdowns. As they do not have results in fielddata/ nor in formchoices/, so answerChoicesAsObjects will not be set from e.g. loadFieldDataFn for them.
    this._setAnswerChoicesAsObjects(_formQuestion.choices || []);
    this.answer = extractAnswer(_formQuestion.type, _currentAnswer || FormAnswerEmpty);
    switch (_formQuestion.type) {
      case FormQuestionType.TextEmail:
        this.formQuestion.validation.rgx = {
          p: /[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/
        };
        break;
      case FormQuestionType.TextUrl:
        this.formQuestion.validation.rgx = {
          p: /((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/
        };
        break;
      default:
    }
    this._update(true, _formQuestion, _currentAnswer);
  }
  get answerModified() {
    if (this.formQuestion.type === FormQuestionType.Attachment) {
      return this.hasAttachmentFieldModified();
    } else {
      return this.modifiedAnswer !== undefined;
    }
  }
  get currentAnswer() {
    if (this.modifiedAnswer !== undefined) {
      return this.modifiedAnswer;
    }
    if (this.answer != null) {
      return this.answer;
    }
    return FormAnswerEmpty;
  }
  get isAsyncChoicesField() {
    var _this$issueFieldData, _this$issueFieldData$, _this$issueFieldData2, _this$issueFieldData3;
    const linkedJiraFieldSchemaSystem = (_this$issueFieldData = this.issueFieldData) === null || _this$issueFieldData === void 0 ? void 0 : (_this$issueFieldData$ = _this$issueFieldData.schema) === null || _this$issueFieldData$ === void 0 ? void 0 : _this$issueFieldData$.system;
    if (linkedJiraFieldSchemaSystem && asyncChoicesFieldSchemaSystems.includes(linkedJiraFieldSchemaSystem)) {
      return true;
    }
    const linkedJiraFieldSchemaCustom = (_this$issueFieldData2 = this.issueFieldData) === null || _this$issueFieldData2 === void 0 ? void 0 : (_this$issueFieldData3 = _this$issueFieldData2.schema) === null || _this$issueFieldData3 === void 0 ? void 0 : _this$issueFieldData3.custom;
    if (linkedJiraFieldSchemaCustom && (fg('jcs_master_flag') ? asyncChoicesFieldSchemaCustomsWithEntitlement : asyncChoicesFieldSchemaCustoms).includes(linkedJiraFieldSchemaCustom)) {
      return true;
    }
    return false;
  }
  get validationErrors() {
    var _this$issueFieldData4;
    if (this.answerModified || this.validating) {
      const currentAnswerForTextQuestion = toTextQuestionAnswer(this.currentAnswer) || '';
      switch (this.formQuestion.type) {
        case FormQuestionType.TextShort:
        case FormQuestionType.TextLong:
        case FormQuestionType.TextParagraph:
          return getTextValidation(this.formQuestion.validation, currentAnswerForTextQuestion, undefined, this.formQuestion.label);
        case FormQuestionType.RichTextParagraph:
          return getRichTextValidation(this.formQuestion.validation, toJS(toRichTextQuestionAnswer(this.currentAnswer)) || getEmptyADF(), undefined, this.formQuestion.label);
        case FormQuestionType.TextEmail:
          return getTextValidation(this.formQuestion.validation, currentAnswerForTextQuestion, FormQuestionType.TextEmail, this.formQuestion.label);
        case FormQuestionType.TextUrl:
          return getTextValidation(this.formQuestion.validation, currentAnswerForTextQuestion, FormQuestionType.TextUrl, this.formQuestion.label);
        case FormQuestionType.Number:
          return getNumberValidation(this.formQuestion.validation, currentAnswerForTextQuestion, this.formQuestion.label);
        case FormQuestionType.DateTime:
          return getDateTimeValidation(this.formQuestion.validation, toDateTimeQuestionAnswerOrEmpty(this.currentAnswer), this.formQuestion.label);
        case FormQuestionType.Date:
          return getDateValidation(this.formQuestion.validation, currentAnswerForTextQuestion, this.formQuestion.label);
        case FormQuestionType.Time:
          return getTimeValidation(this.formQuestion.validation, currentAnswerForTextQuestion, this.formQuestion.label);
        case FormQuestionType.ChoiceCascading:
        case FormQuestionType.ChoiceSingle:
        case FormQuestionType.ChoiceMultiple:
        case FormQuestionType.ChoiceDropdown:
        case FormQuestionType.ChoiceDropdownMultiple:
        case FormQuestionType.CmdbObject:
          return getChoiceValidation(this.formQuestion.validation, this.choices, toChoiceQuestionAnswerOrEmpty(this.currentAnswer), (_this$issueFieldData4 = this.issueFieldData) === null || _this$issueFieldData4 === void 0 ? void 0 : _this$issueFieldData4.asyncRequiredChoice, this.formQuestion.label);
        case FormQuestionType.UserSingle:
        case FormQuestionType.UserMultiple:
          return getUserPickerValidation(this.formQuestion.validation, toUserPickerQuestionAnswer(this.currentAnswer) || [], this.formQuestion.label);
        case FormQuestionType.Attachment:
          return getAttachmentValidation(this.formQuestion.validation, toJS(toAttachmentQuestionAnswer(this.currentAnswer)) || [], this.formQuestion.type, this.formQuestion.label);
        default:
      }
    }
  }
}, _QuestionStore.optionToChoice = option => {
  var _option$children;
  return {
    id: option.value,
    label: option.label,
    children: (_option$children = option.children) === null || _option$children === void 0 ? void 0 : _option$children.map(_QuestionStore.optionToChoice),
    ...(fg('proforma_asset_fields_show_selected_attributes') ? {
      cmdbObject: option.cmdbObject
    } : {})
  };
}, _QuestionStore), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "answer", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modifiedAnswer", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "validating", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "loading", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isUploadingMedia", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "choices", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "answerChoicesAsObjects", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "dependentFieldChangedCount", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 0;
  }
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "otherChoiceId", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "revisionToken", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "issueFieldData", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "answerModified", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "answerModified"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "currentAnswer", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "currentAnswer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isAsyncChoicesField", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "isAsyncChoicesField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "validationErrors", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "validationErrors"), _class.prototype), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "setLastUpdated", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return () => {
      this.revisionToken = `${this.id}-${Date.now()}`;
    };
  }
}), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "setAnswer", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return (answer, modifiedAnswer = true) => {
      if (modifiedAnswer) {
        this.modifiedAnswer = answer;
        if (this.answerModified) {
          var _this$onFieldDirty;
          (_this$onFieldDirty = this.onFieldDirty) === null || _this$onFieldDirty === void 0 ? void 0 : _this$onFieldDirty.call(this);
        }
      } else {
        this.modifiedAnswer = undefined;
        this.answer = answer;
      }
      if (isChoiceQuestionAnswer(answer) && answer.choiceObjects != null) {
        this.setAnswerChoicesAsObjects(answer.choiceObjects);
      }
      this.setLastUpdated();
      this.onFieldChange({
        jiraField: this.formQuestion.jiraField,
        questionType: this.formQuestion.type
      }, toTextQuestionAnswer(answer) || '');
    };
  }
}), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "doPopulateDefaultValueOnEmptySearch", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return () => {
      var _this$issueFieldData5;
      if (!this.populateDefaultValueOnEmptySearch || !((_this$issueFieldData5 = this.issueFieldData) !== null && _this$issueFieldData5 !== void 0 && _this$issueFieldData5.populateDefaultValueOnEmptySearch) || !this.isAsyncChoicesField) {
        return Promise.resolve();
      }
      this.loading = true;
      return this.loadChoicesAsync('').then(options => {
        var _this$formQuestion$va, _this$issueFieldData$2, _this$issueFieldData6;
        const choices = options.map(QuestionStore.optionToChoice);
        const maxAllowedChoices = Math.min((_this$formQuestion$va = this.formQuestion.validation.mxs) !== null && _this$formQuestion$va !== void 0 ? _this$formQuestion$va : choices.length, (_this$issueFieldData$2 = (_this$issueFieldData6 = this.issueFieldData) === null || _this$issueFieldData6 === void 0 ? void 0 : _this$issueFieldData6.maxAllowedChoices) !== null && _this$issueFieldData$2 !== void 0 ? _this$issueFieldData$2 : choices.length);
        const validatedChoices = choices.splice(0, maxAllowedChoices);
        this.setAnswer({
          choices: validatedChoices.map(({
            id
          }) => id),
          choiceObjects: validatedChoices
        }, false);
      }).finally(() => {
        this.loading = false;
      });
    };
  }
}), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "onDependentFieldChanged", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return () => {
      this.dependentFieldChangedCount++;
    };
  }
}), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "validate", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return () => {
      this.validating = true;
    };
  }
}), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "hideValidation", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return () => {
      this.validating = false;
      this.setLastUpdated();
    };
  }
}), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "setUploadingMedia", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return isUploadingMedia => {
      this.isUploadingMedia = isUploadingMedia;
    };
  }
}), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "discardModifiedAnswer", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return () => {
      this.modifiedAnswer = undefined;
      this.setLastUpdated();
    };
  }
}), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "setAnswerFromFieldValue", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return answer => {
      this.answer = extractAnswer(this.formQuestion.type, answer);
      this.modifiedAnswer = undefined;
      this.setLastUpdated();
    };
  }
}), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "setChoices", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return choices => {
      // Populate choiceObjects in the existing answer for choices where only the ID was known previously (e.g. default answers).
      const currentAnswerChoices = this.getAnswerChoicesAsObjects().map(QuestionStore.optionToChoice);
      const newAnswerChoices = choices.filter(choice => {
        var _toChoiceQuestionAnsw;
        return ((_toChoiceQuestionAnsw = toChoiceQuestionAnswer(this.currentAnswer)) === null || _toChoiceQuestionAnsw === void 0 ? void 0 : _toChoiceQuestionAnsw.choices.includes(choice.id)) && !currentAnswerChoices.some(({
          id
        }) => id === choice.id);
      });
      this.setAnswerChoicesAsObjects([...currentAnswerChoices, ...newAnswerChoices]);
      this._setChoices(choices);
      this.setLastUpdated();
    };
  }
}), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "setAnswerChoicesAsObjects", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return choices => {
      let sortedChoices;
      if (!this._isAsyncChoicesQuestion()) {
        // sort in the same order as this.choices from API - to have same sorting order between Open and Submitted form states
        sortedChoices = choices.slice().sort((a, b) => this.choices.findIndex(choice => choice.id === a.id) - this.choices.findIndex(choice => choice.id === b.id));
      } else {
        sortedChoices = choices.slice().sort(sortById); // same as at the BE
      }
      this._setAnswerChoicesAsObjects(sortedChoices);
      this.setLastUpdated();
    };
  }
}), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "setIssueFieldData", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return issueFieldData => {
      this.issueFieldData = issueFieldData;
      this.setLastUpdated();
    };
  }
}), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "update", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return (formQuestion, currentAnswer) => this._update(false, formQuestion, currentAnswer);
  }
})), _class);