/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Turkish (Turkey)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Başka bir konu oluştur',
  'proforma-jira-issue-forms.common.ui.footer.create-another-label-issue-term-refresh': 'Başka bir bilet oluştur',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Oluştur',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Lütfen daha sonra yeniden deneyin.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Konunuz oluşturulamadı.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title-issue-term-refresh': 'Konunuz oluşturulamadı.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Geçersiz yanıtları düzeltin ve tüm zorunlu alanların doldurulduğundan emin olun.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Bağlantıyı kopyala',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': '"{issueKey}" konusunu oluşturdunuz.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title-issue-term-refresh': '"{issueKey}" biletsunu oluşturdunuz.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Konuyu görüntüle',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action-issue-term-refresh': 'Konuyu görüntüle',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Formu düzenle',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Talep formunu düzenleyin',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'Yöneticinizden <strong>{formFieldName}</strong> alanını koşullu bölümden taşımasını veya <strong>{typeName}</strong> talep formunda bağlı olduğu <strong>{jiraFieldName}</strong> Jira alanından &quot;zorunlu&quot; durumunu kaldırmasını isteyin.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': '<strong>{formFieldName}</strong> alanını koşullu bölümden taşıyın veya <strong>{typeName}</strong> talep formunda bağlı olduğu <strong>{jiraFieldName}</strong> Jira alanından &quot;zorunlu&quot; durumunu kaldırın.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': 'Koşullu bölümdeki "zorunlu" alan',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Yöneticinizden bu formdaki bir alanı <strong>{typeName}</strong> talep formundaki <strong>{jiraFieldName}</strong> Jira alanına bağlamasını isteyin.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Bu formdaki bir alanı <strong>{typeName}</strong> talep formundaki <strong>{jiraFieldName}</strong> Jira alanına bağlayın.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': '"Zorunlu" alan bağlanmamış',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Yöneticinizden <strong>{typeName}</strong> talep formunda bağlı olduğu <strong>{jiraFieldName}</strong> Jira alanıyla eşleşmesi için bu formdaki <strong>{formFieldName}</strong> alanını &quot;zorunlu&quot; olarak işaretlemesini isteyin.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': '<strong>{typeName}</strong> talep formunda bağlı olduğu <strong>{jiraFieldName}</strong> Jira alanıyla eşleşmesi için bu formdaki <strong>{formFieldName}</strong> alanını &quot;gerekli&quot; olarak işaretleyin.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': '"Gerekli" alan eşleşmiyor',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Sayfayı yenileyin ve tekrar deneyin.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Formunuz yüklenemedi',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Artık Varlıklar özel alanı yapılandırma filtresinde mevcut olmayan nitelikleri kaldırın ve AQL sorgusunun geçerli olduğundan emin olun.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'Bu alan için hiçbir nesne alınamadı',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Başka bir konu oluştur',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed-issue-term-refresh': 'Başka bir bilet oluştur',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Bu konuyu güncellemek veya yönetmek için <a>issueKey</a> anahtarlı konuyu görüntüleyin.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed-issue-term-refresh': 'Bu bileti güncellemek veya yönetmek için <a>issueKey</a> anahtarlı bileti görüntüleyin.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Konunuz oluşturuldu',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed-issue-term-refresh': 'Konunuz oluşturuldu',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Bu bağlantıyı herhangi bir Atlassian sitesine yapıştırıp ekli formları kullanarak konu oluşturun. <a>Bağlantıyı kopyalayın</a>',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed-issue-term-refresh': 'Bu bağlantıyı herhangi bir Atlassian sitesine yapıştırıp ekli formları kullanarak bilet oluşturun. <a>Bağlantıyı kopyalayın</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Sayfayı yenileyin ve tekrar deneyin.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Formunuz yüklenirken bir sorunla karşılaşıldı',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Sayfayı yenileyin ve tekrar deneyin.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Projeleriniz yüklenirken bir sorunla karşılaşıldı',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Bir form seçin',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Bir konu türü seçin',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder-issue-term-refresh': 'Bir bilet türü seçin',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Doğrudan bir formu veya forma yönlendiren bir bağlantıyı kullanarak konu oluşturun. <a>Konu oluşturmak için formları kullanmak hakkında daha fazla bilgi edinin.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed-issue-term-refresh': 'Doğrudan bir formu veya forma yönlendiren bir bağlantıyı kullanarak bilet oluşturun. <a>Konu oluşturmak için formları kullanmak hakkında daha fazla bilgi edinin.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Konu oluşturun',
  'proforma-jira-issue-forms.ui.general.header.page-header-issue-term-refresh': 'Konu oluşturun',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Bu destek projesinde ilişkili form yok.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Bu yazılım projesinde ilişkili form yok.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Bu iş projesinde ilişkili form yok.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Bir proje seçin',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Bir talep türü seçin'
};