import { createContainer, createHook, createStore } from 'react-sweet-state';
import { fg } from '@atlaskit/platform-feature-flags';
import { convertFormAnswersToRequest } from './utils';
const initialState = {
  data: undefined,
  error: undefined,
  loading: false
};
const actions = {
  createIssue: (formDetails, templateFormUuid, answers) => ({
    getState,
    setState
  }, {
    apiUtil
  }) => {
    if (!getState().loading) {
      var _formDetails$requestT, _formDetails$requestT2;
      setState({
        data: undefined,
        error: undefined,
        loading: true
      });
      (fg('proforma_use_new_issue_create_route') ? apiUtil.jiraPost('/rest/proforma/internal/1/issue', {
        form: {
          answers: convertFormAnswersToRequest(answers),
          templateFormId: templateFormUuid
        },
        fields: {
          project: {
            id: formDetails.projectId
          },
          issuetype: {
            id: formDetails.issueType.id
          }
        },
        requestTypeId: (_formDetails$requestT = formDetails.requestType) === null || _formDetails$requestT === void 0 ? void 0 : _formDetails$requestT.id
      }) : apiUtil.put('/api/2/createissue', {
        projectId: formDetails.projectId,
        templateFormId: formDetails.projectFormId,
        issueTypeId: formDetails.issueType.id,
        requestTypeId: (_formDetails$requestT2 = formDetails.requestType) === null || _formDetails$requestT2 === void 0 ? void 0 : _formDetails$requestT2.id,
        answers
      })).then(response => {
        if (fg('proforma_use_new_issue_create_route')) {
          setState({
            data: {
              issueKey: response.key
            },
            loading: false
          });
        } else {
          setState({
            data: response,
            loading: false
          });
        }
      }).catch(apiError => {
        setState({
          error: apiError,
          loading: false
        });
      });
    }
  },
  reset: () => ({
    setState
  }) => {
    setState(initialState);
  }
};
const Store = createStore({
  initialState,
  actions,
  name: 'IssueCreateService'
});
export const IssueCreateServiceContainer = createContainer(Store);
export const useIssueCreateService = createHook(Store);