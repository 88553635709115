import _initializerDefineProperty from "@babel/runtime/helpers/initializerDefineProperty";
import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";
import _initializerWarningHelper from "@babel/runtime/helpers/initializerWarningHelper";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;
import { action, computed, observable, runInAction } from 'mobx';
import { filter } from '@atlaskit/adf-utils/traverse';
import { FormQuestionType, FormVisibility } from '../models/Form';
import { getFormAnalyticsAttributes } from '../utils';
import { QuestionStore, toChoiceQuestionAnswer } from './QuestionStore';
export let FormStore = (_class = class FormStore {
  /**
   * Creates a new form store.
   *
   * @param intl The `intl` instance to pass to the `QuestionStore`s
   * @param createAnalyticsEvent The function to create an analytics event so it can be fired
   * @param loadFieldDataFn The function to generate the function to load the field data
   * @param loadChoicesFn The function to generate the function to load the form choices
   * @param loadChoicesAsyncFn The function to generate the function to load form choices async
   * @param searchUsers The function to search for users
   * @param searchUsersWithFieldConfig The function to search for users
   * @param form The form to populate the store with
   * @param populateDefaultValueOnEmptySearch For fields that support it (e.g. CMDB), whether to populate the field with a default value retrieved on an empty search query.
   * @param issueKey The key of the current issue, if known
   * @param jiraFieldMap The map of Jira fields (only used in the Form Builder)
   * @param onFieldChange optional callback function that is passed down to the QuestionStore on the portal create screen and is run when a question's answer changes
   * @param onFieldDirty optional callback function that is passed down to the QuestionStore on the portal create screen
   * and is run when a form is considered dirty. It has different behaviour to onFieldChange.
   */
  constructor(intl, createAnalyticsEvent, loadFieldDataFn, loadChoicesFn, loadChoicesAsyncFn, searchUsers, searchUsersWithFieldConfig, _form, populateDefaultValueOnEmptySearch, issueKey, jiraFieldMap, onFieldChange, onFieldDirty) {
    var _form$publish, _form$publish2;
    this.formId = void 0;
    this.issueKey = void 0;
    _initializerDefineProperty(this, "savingAnswers", _descriptor, this);
    _initializerDefineProperty(this, "submittingForm", _descriptor2, this);
    this.templateFormId = void 0;
    this.templateFormUuid = void 0;
    this.formName = void 0;
    this.language = void 0;
    _initializerDefineProperty(this, "updated", _descriptor3, this);
    _initializerDefineProperty(this, "status", _descriptor4, this);
    this.submitLock = void 0;
    this.submitPdf = void 0;
    _initializerDefineProperty(this, "questions", _descriptor5, this);
    this.layout = void 0;
    _initializerDefineProperty(this, "internal", _descriptor6, this);
    this.conditions = void 0;
    this.sections = void 0;
    _initializerDefineProperty(this, "invalid", _descriptor7, this);
    this.createOpen = void 0;
    this.validateOnCreate = void 0;
    this.portalCanSubmit = void 0;
    this.analyticsAttributes = void 0;
    this.prevVisibleSections = void 0;
    this.primaryLocale = void 0;
    this.translatedLocale = void 0;
    this.loadFieldData = void 0;
    this.loadChoices = void 0;
    this.loadChoicesAsync = void 0;
    this.searchUsers = void 0;
    this.searchUsersWithFieldConfig = void 0;
    _initializerDefineProperty(this, "attachQuestionsToSections", _descriptor8, this);
    this.getQuestionIdsFromSectionContent = sectionContent => {
      return filter(sectionContent, node => {
        if (!node.attrs || !node.attrs.parameters || !node.attrs.parameters.id) {
          return false;
        }
        return node.attrs.extensionKey === 'question';
      }).map(node => node.attrs.parameters.id.toString());
    };
    _initializerDefineProperty(this, "update", _descriptor9, this);
    this._update = (constructing, form, refreshChoices = false) => {
      this.updated = form.updated;
      this.status = form.state.status;
      this.internal = form.state.visibility === FormVisibility.Internal;
      if (!constructing) {
        this.invalid = false;
        this.questions.forEach(question => {
          const questionKey = question.id.toString();
          const formQuestion = {
            ...form.design.questions[questionKey],
            choices: question.choices // Retain the current choices.
          };
          question.update(formQuestion, form.state.answers[questionKey]);
        });
        if (!refreshChoices) {
          return this.loadFieldData().then(() => this.afterUpdate());
        }
      }
      return Promise.all([this.loadFieldData(), this.loadChoices()]).then(() => this.afterUpdate());
    };
    this.validate = () => {
      const visibleQuestions = this.questions.filter(question => !this.hiddenQuestions.includes(question.id.toString()));

      // Validate each question
      let allQuestionsValid = true;
      visibleQuestions.forEach(questionStore => {
        // activate validations on question
        questionStore.validate();
        if (!!questionStore.validationErrors) {
          allQuestionsValid = false;
        }
      });
      runInAction(() => {
        this.invalid = !allQuestionsValid;
      });
    };
    _initializerDefineProperty(this, "hideValidations", _descriptor10, this);
    _initializerDefineProperty(this, "discardModifiedAnswers", _descriptor11, this);
    _initializerDefineProperty(this, "onFieldChange", _descriptor12, this);
    this.formId = _form.id;
    this.issueKey = issueKey;
    const formSettings = _form.design.settings;
    this.templateFormId = formSettings.templateId;
    this.templateFormUuid = formSettings.templateFormUuid;
    this.formName = formSettings.name;
    this.language = formSettings.language;
    this.submitLock = formSettings.submit.lock;
    this.submitPdf = formSettings.submit.pdf;
    this.createOpen = ((_form$publish = _form.publish) === null || _form$publish === void 0 ? void 0 : _form$publish.portal) !== undefined && !_form.publish.portal.submitOnCreate;
    this.validateOnCreate = !this.createOpen || ((_form$publish2 = _form.publish) === null || _form$publish2 === void 0 ? void 0 : _form$publish2.portal) !== undefined && _form.publish.portal.validateOnCreate;
    this.portalCanSubmit = !formSettings.portal || formSettings.portal.canSubmit;
    this.conditions = _form.design.conditions;
    this.layout = _form.design.layout;
    this.sections = {
      ..._form.design.sections
    };
    this.attachQuestionsToSections();
    this.analyticsAttributes = {
      ...getFormAnalyticsAttributes(_form),
      formId: this.formId,
      templateFormId: this.templateFormId
    };
    this.primaryLocale = formSettings.primaryLocale;
    this.translatedLocale = formSettings.translatedLocale;
    if (_form.design.questions) {
      this.questions = Object.keys(_form.design.questions).map(questionKey => {
        const formQuestion = _form.design.questions[questionKey];
        return new QuestionStore(intl, questionKey, formQuestion, query => loadChoicesAsyncFn(this)(parseInt(questionKey, 10), query), (fieldMeta, value) => {
          this.onFieldChange(fieldMeta.jiraField);
          onFieldChange === null || onFieldChange === void 0 ? void 0 : onFieldChange(fieldMeta, value);
        }, populateDefaultValueOnEmptySearch, _form.state.answers[questionKey], onFieldDirty);
      });
    } else {
      this.questions = [];
    }
    this.loadFieldData = loadFieldDataFn(this);
    this.loadChoices = loadChoicesFn(this);
    this.loadChoicesAsync = loadChoicesAsyncFn(this);
    this.searchUsers = searchUsers;
    this.searchUsersWithFieldConfig = searchUsersWithFieldConfig;
    this._update(true, _form);
  }
  get formIsBlank() {
    if (!this.layout) {
      return true;
    }
    return this.layout.every(subLayout => subLayout.content.length === 0);
  }
  get answersModified() {
    for (const questionStore of this.questions) {
      if (questionStore.answerModified) {
        return true;
      }
    }
    return false;
  }
  get satisfiedConditions() {
    if (!this.conditions) {
      return [];
    }
    const satisfiedConditions = [];
    Object.keys(this.conditions).forEach(conditionKey => {
      // @ts-ignore
      const condition = this.conditions[conditionKey];
      if (this.conditionIsSatisfied(condition)) {
        satisfiedConditions.push(conditionKey);
      }
    });
    return satisfiedConditions;
  }
  conditionIsSatisfied(condition) {
    // Currently this code only supports conditions with 'choice - one of' inputs
    if (condition.i.co) {
      // Currently this code only supports one input per condition, because it returns from the first iteration of this loop
      for (const conditionQuestionId in condition.i.co.cIds) {
        const trackedQuestion = this.questions.find(question => `${question.id}` === conditionQuestionId);
        const currentAnswer = toChoiceQuestionAnswer(trackedQuestion === null || trackedQuestion === void 0 ? void 0 : trackedQuestion.currentAnswer);
        if (!(currentAnswer !== null && currentAnswer !== void 0 && currentAnswer.choices)) {
          return false;
        }
        const conditionInput = condition.i.co.cIds[conditionQuestionId];
        return currentAnswer.choices.some(choice => conditionInput.includes(choice) || conditionInput.includes(choice.split(':')[0]));
      }
    }
    return false;
  }
  get visibleSections() {
    if (!this.sections) {
      return ['0'];
    }
    const {
      satisfiedConditions
    } = this;
    const visibleSections = ['0'];
    Object.keys(this.sections).forEach(sectionKey => {
      const section = this.sections[sectionKey];
      if (!section.conditions) {
        visibleSections.push(sectionKey);
      }
      if (section.conditions && section.conditions.every(condition => satisfiedConditions.includes(condition))) {
        visibleSections.push(sectionKey);
      }
    });
    return visibleSections;
  }
  get hiddenQuestions() {
    if (!this.sections) {
      return this.questions.map(question => question.id.toString());
    }
    const hiddenSections = [];
    Object.keys(this.sections).forEach(sectionKey => {
      if (!this.visibleSections.includes(sectionKey)) {
        hiddenSections.push(this.sections[sectionKey]);
      }
    });
    return this.questions.filter(question => {
      return hiddenSections.some(hiddenSection => hiddenSection.questions && hiddenSection.questions.includes(question.id.toString()));
    }).map(question => question.id.toString());
  }
  get visibleQuestions() {
    return this.questions.filter(question => !this.hiddenQuestions.includes(question.id.toString()));
  }
  get isUploadingMedia() {
    for (const questionStore of this.questions) {
      if (questionStore.isUploadingMedia) {
        return true;
      }
    }
    return false;
  }
  get hasAttachmentField() {
    for (const questionStore of this.questions) {
      if (questionStore.formQuestion.type === FormQuestionType.Attachment) {
        return true;
      }
    }
    return false;
  }
  get numberOfAttachment() {
    let size = 0;
    for (const questionStore of this.questions) {
      if (questionStore.formQuestion.type === FormQuestionType.Attachment) {
        const attachments = questionStore.currentAnswer;
        size += attachments.length;
      }
    }
    return size;
  }
  get attachmentIds() {
    const attachmentIds = new Set();
    for (const questionStore of this.questions) {
      if (questionStore.formQuestion.type === FormQuestionType.Attachment) {
        const attachments = questionStore.answer ? questionStore.answer : [];
        attachments.forEach(attachment => attachmentIds.add(attachment.id));
      }
    }
    return attachmentIds;
  }
  afterUpdate() {
    this.prevVisibleSections = this.visibleSections;
    this.visibleQuestions.forEach(question => question.doPopulateDefaultValueOnEmptySearch());
  }
  getNewlyVisibleQuestions() {
    if (!this.prevVisibleSections) {
      // Don't calculate any questions as becoming newly visible until the form is loaded fully and this field is set.
      return [];
    }
    const newlyVisibleSections = this.visibleSections.filter(sectionId => {
      var _this$prevVisibleSect;
      return !((_this$prevVisibleSect = this.prevVisibleSections) !== null && _this$prevVisibleSect !== void 0 && _this$prevVisibleSect.includes(sectionId));
    });
    const newlyVisibleQuestions = newlyVisibleSections.reduce((accumulatedQuestions, sectionId) => {
      var _this$sections;
      const section = (_this$sections = this.sections) === null || _this$sections === void 0 ? void 0 : _this$sections[sectionId];
      if (!section) {
        return accumulatedQuestions;
      }
      return [...accumulatedQuestions, ...this.questions.filter(question => {
        var _section$questions;
        return (_section$questions = section.questions) === null || _section$questions === void 0 ? void 0 : _section$questions.includes(question.id.toString());
      })];
    }, []);
    this.prevVisibleSections = this.visibleSections;
    return newlyVisibleQuestions;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "savingAnswers", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "submittingForm", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "updated", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "status", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "questions", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "internal", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "invalid", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "formIsBlank", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "formIsBlank"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "answersModified", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "answersModified"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "satisfiedConditions", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "satisfiedConditions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "visibleSections", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "visibleSections"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hiddenQuestions", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "hiddenQuestions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "visibleQuestions", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "visibleQuestions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isUploadingMedia", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "isUploadingMedia"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "hasAttachmentField", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "hasAttachmentField"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "numberOfAttachment", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "numberOfAttachment"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "attachmentIds", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "attachmentIds"), _class.prototype), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "attachQuestionsToSections", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return () => {
      if (!this.layout || !this.layout[0]) {
        return;
      }
      const sectionsAdf = this.layout.map((sectionLayout, index) => ({
        id: index,
        questions: sectionLayout.content ? this.getQuestionIdsFromSectionContent(sectionLayout) : []
      }));
      sectionsAdf.forEach(section => {
        const {
          sections
        } = this;
        if (sections && sections[section.id]) {
          sections[section.id].questions = section.questions;
        }
      });
    };
  }
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "update", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return (form, refreshChoices = false) => {
      return this._update(false, form, refreshChoices);
    };
  }
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "hideValidations", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return () => {
      this.invalid = false;
      this.questions.forEach(question => question.hideValidation());
    };
  }
}), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "discardModifiedAnswers", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return () => {
      this.questions.forEach(question => question.discardModifiedAnswer());
    };
  }
}), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "onFieldChange", [action], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return jiraField => {
      const dependentVisibleQuestions = jiraField ? this.visibleQuestions.filter(question => {
        var _question$issueFieldD, _question$issueFieldD2;
        return (_question$issueFieldD = question.issueFieldData) === null || _question$issueFieldD === void 0 ? void 0 : (_question$issueFieldD2 = _question$issueFieldD.dependsOn) === null || _question$issueFieldD2 === void 0 ? void 0 : _question$issueFieldD2.includes(jiraField);
      }) : [];
      const newlyVisibleNonDependentQuestions = this.getNewlyVisibleQuestions().filter(newlyVisibleQuestion => !dependentVisibleQuestions.some(dependentQuestion => dependentQuestion.id === newlyVisibleQuestion.id));
      dependentVisibleQuestions.forEach(question => {
        question.onDependentFieldChanged();
        question.doPopulateDefaultValueOnEmptySearch();
      });
      newlyVisibleNonDependentQuestions.forEach(question => question.doPopulateDefaultValueOnEmptySearch());
    };
  }
})), _class);