/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Italian (Italy)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Crea un altro ticket',
  'proforma-jira-issue-forms.common.ui.footer.create-another-label-issue-term-refresh': 'Crea un altro ticket',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Crea',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Riprova più tardi.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Non è stato possibile creare il ticket.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title-issue-term-refresh': 'Non è stato possibile creare il ticket.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Risolvi eventuali risposte non valide e verifica che tutti i campi obbligatori siano stati completati.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Copia il link',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Hai creato il ticket "{issueKey}".',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title-issue-term-refresh': 'Hai creato il ticket "{issueKey}".',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Visualizza il ticket',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action-issue-term-refresh': 'Visualizza il ticket',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Modifica il modulo',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Modifica il modulo di richiesta',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': "Chiedi all'amministratore di spostare il campo <strong>{formFieldName}</strong> all'esterno della sezione condizionale o di rimuovere lo stato &quot;obbligatorio&quot; dal campo Jira <strong>{jiraFieldName}</strong> collegato nel modulo di richiesta <strong>{typeName}</strong>.",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': "Sposta il campo <strong>{formFieldName}</strong> all'esterno della sezione condizionale, oppure rimuovi lo stato &quot;obbligatorio&quot; dal campo Jira <strong>{jiraFieldName}</strong> collegato nel modulo di richiesta <strong>{typeName}</strong>.",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': 'Campo "obbligatorio" nella sezione condizionale',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': "Chiedi all'amministratore di collegare un campo di questo modulo al campo Jira <strong>{jiraFieldName}</strong> nel modulo di richiesta <strong>{typeName}</strong>.",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Collega un campo di questo modulo al campo Jira <strong>{jiraFieldName}</strong> nel modulo di richiesta <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': 'Il campo "obbligatorio" non è collegato',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': "Chiedi all'amministratore di contrassegnare il campo <strong>{formFieldName}</strong> in questo modulo come &quot;obbligatorio&quot; in modo che corrisponda al campo Jira <strong>{jiraFieldName}</strong> collegato nel modulo di richiesta <strong>{typeName}</strong>.",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'Contrassegna il campo <strong>{formFieldName}</strong> in questo modulo come &quot;obbligatorio&quot; in modo che corrisponda al campo Jira <strong>{jiraFieldName}</strong> collegato nel modulo di richiesta <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': 'Il campo "obbligatorio" non corrisponde',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Aggiorna la pagina e riprova.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Non è stato possibile caricare il modulo',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Rimuovi gli attributi che non esistono più nel filtro di configurazione dei campi personalizzati di Risorse e assicurati che la query AQL sia valida.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'Impossibile recuperare gli oggetti di questo campo',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Crea un altro ticket',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed-issue-term-refresh': 'Crea un altro ticket',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Visualizza <a>issueKey</a> per aggiornare o gestire questo ticket.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed-issue-term-refresh': 'Visualizza <a>issueKey</a> per aggiornare o gestire questo ticket.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Abbiamo creato il ticket',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed-issue-term-refresh': 'Abbiamo creato il ticket',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Incolla questo link in qualsiasi sito Atlassian per creare ticket usando i moduli incorporati. <a>Copia link</a>',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed-issue-term-refresh': 'Incolla questo link in qualsiasi sito Atlassian per creare ticket usando i moduli incorporati. <a>Copia link</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Aggiorna la pagina e riprova.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Si è verificato un problema durante il caricamento dei tuoi moduli',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Aggiorna la pagina e riprova.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Si è verificato un problema durante il caricamento dei tuoi progetti',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Seleziona un modulo',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Seleziona un tipo di ticket',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder-issue-term-refresh': 'Seleziona un tipo di ticket',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': "Usa un modulo o un link a un modulo per creare i ticket. <a>Scopri di più sull'uso dei moduli per la creazione di ticket.</a>",
  'proforma-jira-issue-forms.ui.general.header.page-description-embed-issue-term-refresh': "Usa un modulo o un link a un modulo per creare i ticket. <a>Scopri di più sull'uso dei moduli per la creazione di ticket.</a>",
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Crea un ticket',
  'proforma-jira-issue-forms.ui.general.header.page-header-issue-term-refresh': 'Crea un ticket',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Questo progetto di service management non è associato ad alcun modulo.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Questo progetto software non è associato ad alcun modulo.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Questo progetto aziendale non è associato ad alcun modulo.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Seleziona un progetto',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Seleziona un tipo di richiesta'
};