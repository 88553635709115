import { defineMessages } from 'react-intl-next';
export const messages = defineMessages({
  successfullyCreatedButtonEmbed: {
    id: 'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed',
    defaultMessage: 'Create another issue',
    description: 'Create another issue button displayed when the issue is successfully created in embedded ProForma Issue Forms.'
  },
  successfullyCreatedButtonEmbedIssueTermRefresh: {
    id: 'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed-issue-term-refresh',
    defaultMessage: 'Create another work item',
    description: 'Create another issue button displayed when the issue is successfully created in embedded ProForma Issue Forms.'
  },
  successfullyCreatedTitleEmbed: {
    id: 'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed',
    defaultMessage: 'We’ve created your issue',
    description: 'Title displayed when the issue is successfully created in an embedded ProForma Issue Forms.'
  },
  successfullyCreatedTitleEmbedIssueTermRefresh: {
    id: 'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed-issue-term-refresh',
    defaultMessage: 'We’ve created your work item',
    description: 'Title displayed when the issue is successfully created in an embedded ProForma Issue Forms.'
  },
  successfullyCreatedDescriptionWithLinkEmbed: {
    id: 'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed',
    defaultMessage: 'View <a>issueKey</a> to update, or manage this issue.',
    description: 'Description displayed when the issue is successfully created in an embedded ProForma Issue Forms.'
  },
  successfullyCreatedDescriptionWithLinkEmbedIssueTermRefresh: {
    id: 'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed-issue-term-refresh',
    defaultMessage: 'View <a>issueKey</a> to update, or manage this work item.',
    description: 'Description displayed when the issue is successfully created in an embedded ProForma Issue Forms.'
  }
});