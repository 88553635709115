export const convertFormAnswersToRequest = formAnswers => Object.keys(formAnswers).reduce((formAnswersRequest, questionKey) => {
  var _answer$users, _answer$files;
  const answer = formAnswers[questionKey];
  return {
    ...formAnswersRequest,
    [questionKey]: {
      text: answer.text,
      adf: answer.adf,
      date: answer.date,
      time: answer.time,
      choices: answer.choices,
      users: (_answer$users = answer.users) === null || _answer$users === void 0 ? void 0 : _answer$users.map(({
        id
      }) => id),
      files: (_answer$files = answer.files) === null || _answer$files === void 0 ? void 0 : _answer$files.map(({
        id
      }) => id)
    }
  };
}, {});