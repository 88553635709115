import { getCookie } from '@atlassian/browser-storage-controls';

// User agent checking code was copied from JFE
const DESKTOP_SWITCH_COOKIE_KEY = 'jira.mobile.desktop.switch';
const ANDROID_CHROME_USER_AGENT = /Chrome\/[.\d]* Mobile/;
const OLD_ANDROID_USER_AGENT = /Linux;(?: U;)? Android (?:[23]\.\d|4\.0\.[12])/;
const MOBILE_USER_AGENT = /(iPhone;|iPhone Simulator;|iPad;|iPod;|iPod touch;|Linux;(?: U;)? Android)/;
const IPHONE_USER_AGENT = /(iPhone;|iPhone Simulator;|iPod;|iPod touch;)/;
const IPAD_USER_AGENT = /iPad;/;
const isSupportedUserAgent = () => {
  var _navigator, _navigator$userAgent;
  if (!((_navigator = navigator) !== null && _navigator !== void 0 && (_navigator$userAgent = _navigator.userAgent) !== null && _navigator$userAgent !== void 0 && _navigator$userAgent.length)) {
    return false;
  }
  return ANDROID_CHROME_USER_AGENT.test(navigator.userAgent) || MOBILE_USER_AGENT.test(navigator.userAgent) && !OLD_ANDROID_USER_AGENT.test(navigator.userAgent);
};
const isDesktopSwitchEnabled = () => getCookie(DESKTOP_SWITCH_COOKIE_KEY) === 'true';
export function isMobile() {
  return isSupportedUserAgent() && !isDesktopSwitchEnabled();
}
export function isIPhone() {
  var _navigator2, _navigator2$userAgent;
  if (!((_navigator2 = navigator) !== null && _navigator2 !== void 0 && (_navigator2$userAgent = _navigator2.userAgent) !== null && _navigator2$userAgent !== void 0 && _navigator2$userAgent.length)) {
    return false;
  }
  return IPHONE_USER_AGENT.test(navigator.userAgent);
}
export function isIPad() {
  var _navigator3, _navigator3$userAgent, _navigator4, _navigator4$platform;
  if (!navigator || !((_navigator3 = navigator) !== null && _navigator3 !== void 0 && (_navigator3$userAgent = _navigator3.userAgent) !== null && _navigator3$userAgent !== void 0 && _navigator3$userAgent.length) || !((_navigator4 = navigator) !== null && _navigator4 !== void 0 && (_navigator4$platform = _navigator4.platform) !== null && _navigator4$platform !== void 0 && _navigator4$platform.length)) {
    return false;
  }
  const {
    userAgent,
    platform,
    maxTouchPoints
  } = navigator;

  // Check for iPad using user agent
  const isIPadUserGgent = IPAD_USER_AGENT.test(userAgent);

  // Check for iPad in desktop mode (iOS 13+)
  const isIPadDesktop = platform === 'MacIntel' && maxTouchPoints > 1;
  return isIPadUserGgent || isIPadDesktop;
}