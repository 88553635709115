/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Finnish
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Luo toinen asia',
  'proforma-jira-issue-forms.common.ui.footer.create-another-label-issue-term-refresh': 'Luo toinen työtehtävä',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Luo',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Yritä myöhemmin uudelleen.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Asiasi luominen epäonnistui.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title-issue-term-refresh': 'Asiasi luominen epäonnistui.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Korjaa virheelliset vastaukset ja tarkista, että kaikki pakolliset kentät on täytetty.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Kopioi linkki',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Olet luonut asian avaimella "{issueKey}".',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title-issue-term-refresh': 'Olet luonut työtehtävän avaimella "{issueKey}".',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Näytä asia',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action-issue-term-refresh': 'Näytä työtehtävä',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Muokkaa lomaketta',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Muokkaa pyyntölomaketta',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'Pyydä ylläpitäjää siirtämään kenttä <strong>{formFieldName}</strong> pois ehdollisten osiosta tai poista tila &quot;Pakollinen&quot; tyypin <strong>{typeName}</strong> pyyntölomakkeen linkitetystä Jira-kentästä <strong>{jiraFieldName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'Siirrä kenttä <strong>{formFieldName}</strong> pois ehdollisten osiosta tai poista tila &quot;Pakollinen&quot; tyypin <strong>{typeName}</strong> pyyntölomakkeen linkitetystä Jira-kentästä <strong>{jiraFieldName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': '"Pakollinen" kenttä ehdollisten osiossa',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Pyydä ylläpitäjää yhdistämään tämän lomakkeen kenttä tyypin <strong>{typeName}</strong> pyyntölomakkeen Jira-kenttään <strong>{jiraFieldName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Linkitä tämän lomakkeen kenttä tyypin <strong>{typeName}</strong> pyyntölomakkeen Jira-kenttään <strong>{jiraFieldName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': 'Tyypin "Pakollinen" kenttää ei ole linkitetty',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Pyydä ylläpitäjää merkitsemään tämän lomakkeen kentän <strong>{formFieldName}</strong> tyypiksi &quot;Pakollinen&quot;, jotta se vastaa tyypin <strong>{typeName}</strong> pyyntölomakkeen linkitettyä Jira-kenttää <strong>{jiraFieldName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'Merkitse tämän lomakkeen kentän <strong>{formFieldName}</strong> tyypiksi &quot;Pakollinen&quot;, jotta se vastaa tyypin <strong>{typeName}</strong> pyyntölomakkeen yhdistettyä Jira-kenttää <strong>{jiraFieldName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': 'Tyypin "Pakollinen" kenttä ei täsmää',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Päivitä sivu ja yritä uudelleen.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Lomakkeesi lataaminen epäonnistui.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Poista ominaisuudet, joita ei enää ole Resurssien muokattavan kentän määrityssuodattimessa, ja varmista, että AQL-kysely on kelvollinen.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'Tälle kentälle ei voitu hakea kohteita',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Luo toinen asia',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed-issue-term-refresh': 'Luo toinen työtehtävä',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Päivitä tai hallinnoi tätä asiaa näyttämällä <a>issueKey</a>.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed-issue-term-refresh': 'Päivitä tai hallinnoi tätä työtehtävää näyttämällä <a>issueKey</a>.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Asia luotiin',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed-issue-term-refresh': 'Asia luotiin',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Luo asioita käyttämällä upotettuja lomakkeita liittämällä tämä linkki mihin tahansa Atlassian-sivustoon. <a>Kopioi linkki</a>',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed-issue-term-refresh': 'Luo työtehtäviä käyttämällä upotettuja lomakkeita liittämällä tämä linkki mihin tahansa Atlassian-sivustoon. <a>Kopioi linkki</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Päivitä sivu ja yritä uudelleen.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Lomakkeidesi lataamisessa on ongelma',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Päivitä sivu ja yritä uudelleen.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Projektiesi lataaminen ei onnistu',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Valitse lomake',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Valitse asiatyyppi',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder-issue-term-refresh': 'Valitse työtyyppi',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Luo asioita käyttämällä lomaketta tai suoraan linkistä lomakkeeseen. <a>Lue lisää lomakkeiden käytöstä asioiden luomiseen.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed-issue-term-refresh': 'Luo työtehtäviä käyttämällä lomaketta tai suoraan linkistä lomakkeeseen. <a>Lue lisää lomakkeiden käytöstä asioiden luomiseen.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Luo asia',
  'proforma-jira-issue-forms.ui.general.header.page-header-issue-term-refresh': 'Luo työtehtävä',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Tähän palveluprojektiin ei ole liitetty lomakkeita.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Tähän ohjelmistoprojektiin ei ole liitetty lomakkeita.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Tähän liiketoimintaprojektiin ei ole liitetty lomakkeita.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Valitse projekti',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Valitse pyyntötyyppi'
};