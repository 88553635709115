/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Swedish
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': 'Skapa ett annat ärende',
  'proforma-jira-issue-forms.common.ui.footer.create-another-label-issue-term-refresh': 'Skapa ett annat arbetsuppgift',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': 'Skapa',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': 'Försök igen senare.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': 'Vi kunde inte skapa ditt ärende.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title-issue-term-refresh': 'Vi kunde inte skapa ditt arbetsuppgift.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': 'Lös eventuella ogiltiga svar och kontrollera att alla obligatoriska fält är ifyllda.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': 'Kopiera länk',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': 'Du har skapat ärendet "{issueKey}".',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title-issue-term-refresh': 'Du har skapat arbetsuppgiften "{issueKey}".',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': 'Visa ärende',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action-issue-term-refresh': 'Visa arbetsuppgift',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': 'Redigera formuläret',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': 'Redigera förfrågningsformulär',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': 'Be din administratör att flytta fältet <strong>{formFieldName}</strong> från det villkorliga avsnittet, eller ta bort statusen ”obligatoriskt” från det länkade Jira-fältet <strong>{jiraFieldName}</strong> i förfrågningsformuläret <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': 'Flytta fältet <strong>{formFieldName}</strong> från det villkorliga avsnittet, eller ta bort statusen ”obligatoriskt” från det länkade Jira-fältet <strong>{jiraFieldName}</strong> i förfrågningsformuläret <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': '”Obligatoriskt” fält i den villkorliga sektionen',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': 'Be din administratör att länka ett fält i detta formulär till Jira-fältet <strong>{jiraFieldName}</strong> i förfrågningsformuläret <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': 'Länka ett fält i detta formulär till Jira-fältet <strong>{jiraFieldName}</strong> i förfrågningsformuläret <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': '”Obligatoriskt” fält är inte länkat',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': 'Be din administratör att markera fältet <strong>{formFieldName}</strong> i detta formulär som ”obligatoriskt” för att matcha det länkade Jira-fältet <strong>{jiraFieldName}</strong> i förfrågningsformuläret <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': 'Markera fältet <strong>{formFieldName}</strong> i detta formulär som ”obligatoriskt” för att matcha det länkade Jira-fältet <strong>{jiraFieldName}</strong> i förfrågningsformuläret <strong>{typeName}</strong>.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': '”Obligatoriskt” fält matchar inte',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': 'Uppdatera sidan och försök igen.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': 'Vi kunde inte läsa in ditt formulär',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': 'Ta bort attribut som inte längre finns i konfigurationsfiltret för anpassade fält för tillgångar och kontrollera att AQL-frågan är giltig.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': 'Det gick inte att hämta objekt för fältet',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': 'Skapa ett till ärende',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed-issue-term-refresh': 'Skapa ett annat arbetsuppgift',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': 'Visa <a>issueKey</a> för att uppdatera eller hantera ärendet.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed-issue-term-refresh': 'Visa <a>issueKey</a> för att uppdatera eller hantera arbetsuppgiften.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': 'Vi har skapat ditt ärende',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed-issue-term-refresh': 'Vi har skapat ditt arbetsuppgift',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Skapa ärenden med inbäddade formulär genom att klistra in den här länken på valfri Atlassian-webbplats. <a>Kopiera länk</a>',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed-issue-term-refresh': 'Skapa arbetsuppgifter med inbäddade formulär genom att klistra in den här länken på valfri Atlassian-webbplats. <a>Kopiera länk</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': 'Uppdatera sidan och försök igen.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': 'Vi kan inte läsa in dina formulär',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': 'Uppdatera sidan och försök igen.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': 'Vi kan inte läsa in dina projekt',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': 'Välj ett formulär',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': 'Välj en ärendetyp',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder-issue-term-refresh': 'Välj en arbetstyp',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': 'Skapa ärenden med ett formulär, eller från en länk direkt till ett formulär. <a>Läs mer om att använda formulär för att skapa ärenden.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed-issue-term-refresh': 'Skapa arbetsuppgifter med ett formulär, eller från en länk direkt till ett formulär. <a>Läs mer om att använda formulär för att skapa arbetsuppgifter.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': 'Skapa ärende',
  'proforma-jira-issue-forms.ui.general.header.page-header-issue-term-refresh': 'Skapa arbetsuppgift',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': 'Serviceprojektet har inga kopplade formulär.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': 'Programvaruprojektet har inga kopplade formulär.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': 'Affärsprojektet har inga kopplade formulär.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': 'Välj ett projekt',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': 'Välj en förfrågningstyp'
};