/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Chinese (Traditional)
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': '建立另一個議題',
  'proforma-jira-issue-forms.common.ui.footer.create-another-label-issue-term-refresh': '建立另一個工作項目',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': '建立',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': '請稍後再試一次。',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': '我們無法建立您的議題。',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title-issue-term-refresh': '我們無法建立您的工作項目。',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': '處理無效的回答，並檢查所有必填欄位是否已填寫。',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': '複製連結',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': '您已建立「{issueKey}」議題。',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title-issue-term-refresh': '您已建立「{issueKey}」工作項目。',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': '檢視議題',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action-issue-term-refresh': '檢視工作項目',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': '編輯表單',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': '編輯請求表單',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': '要求您的管理員將 <strong>{formFieldName}</strong> 欄位移出條件區段之外，或從 <strong>{typeName}</strong> 請求表單已連結的 <strong>{jiraFieldName}</strong> Jira 欄位中，移除「必填」狀態。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': '將 <strong>{formFieldName}</strong> 欄位移出條件區段之外，或從 <strong>{typeName}</strong> 請求表單已連結的 <strong>{jiraFieldName}</strong> Jira 欄位中，移除「必填」狀態。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': '條件區段中的「必填」欄位',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': '要求您的管理員將此表單中的欄位與 <strong>{typeName}</strong> 請求表單中的 <strong>{jiraFieldName}</strong> Jira 欄位連結。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': '將此表單中的欄位與 <strong>{typeName}</strong> 請求表單中的 <strong>{jiraFieldName}</strong> Jira 欄位連結。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': '「必填」欄位未連結',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': '要求您的管理員將此表單中的 <strong>{formFieldName}</strong> 欄位標示為「必填」，以符合 <strong>{typeName}</strong> 請求表單中，已連結的 <strong>{jiraFieldName}</strong> Jira 欄位。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': '將此表單中的 <strong>{formFieldName}</strong> 欄位標示為「必要」，以符合 <strong>{typeName}</strong> 請求表單中，已連結的 <strong>{jiraFieldName}</strong> Jira 欄位。',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': '「必要」欄位不相符',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': '請重新整理頁面，然後再試一次。',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': '我們無法載入您的表單',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': '移除「Assets 自訂欄位」組態篩選條件中已不存在的屬性，並確保 AQL 查詢有效。',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': '我們無法擷取此欄位的任何物件',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': '建立另一個議題',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed-issue-term-refresh': '建立另一個工作項目',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': '檢視 <a>issueKey</a> 以更新，或管理此議題。',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed-issue-term-refresh': '檢視 <a>issueKey</a> 以更新，或管理此工作項目。',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': '我們已經建立了您的議題',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed-issue-term-refresh': '我們已經建立了您的工作項目',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': '將此連結貼到任何 Atlassian 網站，即可使用嵌入式表單建立議題。<a>複製連結</a>',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed-issue-term-refresh': '將此連結貼到任何 Atlassian 網站，即可使用嵌入式表單建立工作項目。<a>複製連結</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': '請重新整理頁面，然後再試一次。',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': '我們在載入您的表單時遇到問題',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': '請重新整理頁面，然後再試一次。',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': '我們在載入您的專案時遇到問題',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': '選取表單',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': '選取議題類型',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder-issue-term-refresh': '選取工作項目類型',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': '使用表單 (或直接透過表單的連結) 建立議題。<a>進一步了解如何使用表單建立議題。</a>',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed-issue-term-refresh': '使用表單 (或直接透過表單的連結) 建立工作項目。<a>進一步了解如何使用表單建立工作項目。</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': '建立議題',
  'proforma-jira-issue-forms.ui.general.header.page-header-issue-term-refresh': '建立工作項目',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': '此服務專案沒有任何相關聯的表單。',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': '此軟體專案沒有任何相關聯的表單。',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': '此業務專案沒有任何相關聯的表單。',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': '選取專案',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': '選取要求類型'
};