/* prettier-ignore */
/**
 * NOTE:
 *
 * This file is automatically generated by Traduki 2.0.
 * DO NOT CHANGE IT BY HAND or your changes will be lost.
 */
//Korean
export default {
  'proforma-jira-issue-forms.common.ui.footer.create-another-label': '다른 이슈 만들기',
  'proforma-jira-issue-forms.common.ui.footer.create-another-label-issue-term-refresh': '다른 업무 항목 만들기',
  'proforma-jira-issue-forms.common.ui.footer.create-button-label': '만들기',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-description': '나중에 다시 시도하세요.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title': '이슈를 만들 수 없습니다.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-flag-title-issue-term-refresh': '업무 항목를 만들 수 없습니다.',
  'proforma-jira-issue-forms.common.ui.footer.failed-creating-with-save-errors-flag-description': '유효하지 않은 답변을 해결하고 모든 필수 필드를 작성했는지 확인하세요.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-copy-link-action': '링크 복사',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title': '"{issueKey}" 이슈를 만들었습니다.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-title-issue-term-refresh': '"{issueKey}" 업무 항목를 만들었습니다.',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action': '이슈 보기',
  'proforma-jira-issue-forms.common.ui.footer.successfully-created-flag-view-issue-action-issue-term-refresh': '업무 항목 보기',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-edit-form-link-label-for-project-admin': '양식 편집',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-action-for-project-admin': '요청 양식 편집',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-non-project-admin': "관리자에게 <strong>{typeName}</strong> 요청 양식에서 <strong>{formFieldName}</strong> 필드를 조건부 섹션 밖으로 이동하거나 연결된 <strong>{jiraFieldName}</strong> Jira 필드에서 '필수' 상태를 제거하도록 요청합니다.",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-description-for-project-admin': "<strong>{typeName}</strong> 요청 양식에서 <strong>{formFieldName}</strong> 필드를 조건부 섹션 밖으로 이동하거나 연결된 <strong>{jiraFieldName}</strong> Jira 필드에서 '필수' 상태를 제거합니다.",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-is-conditional-title': "조건부 섹션의 '필수' 필드",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-non-project-admin': '관리자에게 이 양식의 필드를 <strong>{typeName}</strong> 요청 양식의 <strong>{jiraFieldName}</strong> Jira 필드와 연결하도록 요청합니다.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-description-for-project-admin': '이 양식의 필드를 <strong>{typeName}</strong> 요청 양식의 <strong>{jiraFieldName}</strong> Jira 필드와 연결합니다.',
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-missing-title': "'필수' 필드가 연결되지 않았습니다",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-non-project-admin': "관리자에게 <strong>{typeName}</strong> 요청 양식의 연결된 <strong>{jiraFieldName}</strong> Jira 필드와 일치하도록 이 양식의 <strong>{formFieldName}</strong> 필드를 '필수'로 표시하도록 요청합니다.",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-description-for-project-admin': "<strong>{typeName}</strong> 요청 양식의 연결된 <strong>{jiraFieldName}</strong> Jira 필드와 일치하도록 이 양식의 <strong>{formFieldName}</strong> 필드를 '필수'로 표시합니다.",
  'proforma-jira-issue-forms.common.utils.form-validation-result-message.conflict-needs-to-be-mandatory-title': "'필수' 필드가 일치하지 않습니다",
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-description': '페이지를 새로 고치고 다시 시도하세요.',
  'proforma-jira-issue-forms.common.utils.process-form-load-error.form-loading-failed-flag-title': '양식을 로드할 수 없습니다',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-description': '자산 사용자 지정 필드 구성 필터에 더 이상 없는 특성을 제거하고 AQL 쿼리가 유효한지 확인하세요.',
  'proforma-jira-issue-forms.controllers.form-store.object-search-error-title': '이 필드에 대해 개체를 가져올 수 없습니다',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed': '다른 이슈 만들기',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-button-embed-issue-term-refresh': '다른 업무 항목 만들기',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed': '이 이슈를 업데이트 또는 관리하려면 <a>issueKey</a>을(를) 확인하세요.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-description-with-link-embed-issue-term-refresh': '이 업무 항목를 업데이트 또는 관리하려면 <a>issueKey</a>을(를) 확인하세요.',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed': '이슈를 만들었습니다',
  'proforma-jira-issue-forms.ui.direct.embed.successfully-created-title-embed-issue-term-refresh': '업무 항목를 만들었습니다',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed': 'Atlassian 사이트에 이 링크를 붙여넣어 포함된 양식을 사용하여 이슈를 만드세요. <a>링크 복사</a>',
  'proforma-jira-issue-forms.ui.general.direct-link-section-message-embed-issue-term-refresh': 'Atlassian 사이트에 이 링크를 붙여넣어 포함된 양식을 사용하여 업무 항목를 만드세요. <a>링크 복사</a>',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-description': '페이지를 새로 고치고 다시 시도하세요.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-project-associated-forms-title': '양식을 로드하는 동안 문제가 발생했습니다',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-description': '페이지를 새로 고치고 다시 시도하세요.',
  'proforma-jira-issue-forms.ui.general.header.error-loading-projects-title': '프로젝트를 로드하는 동안 문제가 발생했습니다',
  'proforma-jira-issue-forms.ui.general.header.form-placeholder': '양식 선택',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder': '이슈 유형 선택',
  'proforma-jira-issue-forms.ui.general.header.issue-type-placeholder-issue-term-refresh': '업무 항목 유형 선택',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed': '양식을 사용하여 이슈를 만들거나 링크에서 양식으로 바로 이슈를 만드세요. <a>양식을 사용하여 이슈를 만드는 방법을 자세히 알아보세요.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-description-embed-issue-term-refresh': '양식을 사용하여 업무 항목를 만들거나 링크에서 양식으로 바로 업무 항목를 만드세요. <a>양식을 사용하여 업무 항목를 만드는 방법을 자세히 알아보세요.</a>',
  'proforma-jira-issue-forms.ui.general.header.page-header': '이슈 만들기',
  'proforma-jira-issue-forms.ui.general.header.page-header-issue-term-refresh': '업무 항목 만들기',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsm': '이 서비스 프로젝트에 연결된 양식이 없습니다.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jsw': '이 소프트웨어 프로젝트에 연결된 양식이 없습니다.',
  'proforma-jira-issue-forms.ui.general.header.project-has-no-associated-forms-jwm': '이 비즈니스 프로젝트에 연결된 양식이 없습니다.',
  'proforma-jira-issue-forms.ui.general.header.project-placeholder': '프로젝트 선택',
  'proforma-jira-issue-forms.ui.general.header.request-type-placeholder': '요청 유형 선택'
};